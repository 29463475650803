













































































import Component, { mixins } from 'vue-class-component';
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';
import {
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';
import { VModel } from '@/mixins/CommonMixins';
import { Prop, Watch } from 'vue-property-decorator';
import { CustomLink } from '@/components/WysiwygEditorExtensions';

@Component({
  components: {
    EditorContent,
    EditorMenuBubble,
  },
})
export default class WysiwygEditor extends mixins(VModel) {
  protected enteredValue = '';

  protected editor: Editor;

  @Prop({
    required: false,
    default: () => true,
  })
  protected editable?: boolean;

  @Watch('value')
  protected handleValueChanged(newVal: string): void {
    this.enteredValue = newVal;
  }

  created(): void {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new CustomLink({ target: '_blank' }),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      // from https://github.com/ueberdosis/tiptap
      // editorProps 	Object 	{} 	A list of Prosemirror editorProps.
      // https://prosemirror.net/docs/ref/#view.EditorProps
      editorProps: {
        handleKeyDown: function (view, e) {
          e.stopPropagation();
        },
      },
    });

    this.editor.on('update', ({ getHTML }) => {
      this.$emit('input', getHTML());
    });
  }

  beforeDestroy(): void {
    this.editor.destroy();
  }
}
